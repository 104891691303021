.actions {
  display: flex;
  justify-content: flex-end;
}

.buttonSubmit {
  composes: success from '~modules/common/components/Forms/styles/buttons.module.css';
  margin: .5rem 0
}

.buttonCancel {
  composes: warning from '~modules/common/components/Forms/styles/buttons.module.css';
  margin: .5rem ;
}