@value desktop from from '~modules/common/styles/screen-sizes.module.css';
.header {
  composes: h2 from '~modules/common/styles/headers.module.css';
}

.selectUserGroup {
  grid-column: 1/1;
  margin: 2rem 0;

  p {
    font-style: italic;
    color: var(--background-color);
    font-size: 1.5rem;
    display: inline;
    margin: 1rem;
  }
}

.hand {
  color: var(--secondary-color);
  font-size: 2rem;
}
