@value breakpoints: './screen-sizes.module.css';
@value mobile, tablet, desktop, desktopMedium, desktopLarge, desktopMassive from breakpoints;
@value variables: '~modules/common/styles/variables.module.css';
@value headerFont from variables;


:global body {
  height: 100%;
  font-family: Helvetica Neue,Arial,sans-serif;
}

:global button { 
  cursor: pointer !important;
}

:global .button { 
  cursor: pointer !important;
}

:global button:disabled { 
  cursor: not-allowed !important;
}

:global .button:disabled { 
  cursor: not-allowed !important;
}

:global img {
  max-width:100%;
  max-height:100%;
}

:global p {
  margin: 0;
  padding: 0;
}


:global select {
  background: whitesmoke;
}

:global ::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
  border-radius: 5px
}

:global ::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 5px;
}


:global .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}


html {
  font-size: 12px;
}

@media mobile {
  :global html {
   font-size: 14px; 
  }
}

@media tablet {
  :global html {
   font-size: 15px; 
  }
}

@media desktop {
  :global html {
   font-size: 16px; 
  }
}

@media desktopMedium {
  :global html {
   font-size: 17px; 
  }
}

@media desktopLarge {
  :global html {
   font-size: 18px; 
  }
} 

@media desktopMassive {
  :global html {
   font-size: 19px; 
  }
} 