@value desktop from from '~modules/common/styles/screen-sizes.module.css';

.container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.content {
  overflow: scroll;
}

.targets,
.services {
  position: relative;
  margin-top: 2.5rem;
}

.title {
  composes: h2 from "~modules/common/styles/headers.module.css";
}

.tooMany {
  margin: 1rem;
  font-size: 1.25rem;
  color: var(--background-color);
  font-style: italic;
  text-align: center;
}

.targets {
  composes: container from '../OrgTargetsPage/orgTargetsPage.module.scss';
}

.services {
  composes: container from '../OrgServicesPage/orgServicesPage.module.scss';
}
.members {
  composes: container from '../OrgMembersPage/orgMembersPage.module.scss';

}

@media desktop {


  .content {
    padding: 0.75rem;
  }
}
