.notification {
  position: relative;
  background: red;
  border-radius: 5px;
  padding: .5rem;
  margin: 0 0 .75rem 0;
}

.notification:focus {
  outline: none;
}

.hide {
  position: absolute;
  top: .2rem;
  right: .2rem;
  background: none;
  border: none;
  font-size: 1rem;
  color: white;
  cursor: pointer;
}

.hide:hover {
  filter: brightness(50%);
}

.message {
  margin: .5rem .75rem;
  color: white;
  font-size: 1rem;
  font-weight: 400;
  min-width: min-content;
  max-width: fit-content;
}

.children {

}

.notification--success {
  compose: notification;
  background: green;
}