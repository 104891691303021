@value tablet-break, desktop-break, desktop-medium-break from '~modules/common/styles/screen-sizes.module.css';

.notFound {
  font-size: 1.5rem;
  text-align: center;
  margin: 3rem;
  color: var(--secondary-color);
  font-style: italic;
  font-weight: 800;
}

tbody,
thead {
  .target,
  .percent,
  .logged {
    text-align: right;
  }

  .status {
    text-align: center;
  }
}

.check {
  color: green;
}

.warning {
  color: orange;
}
