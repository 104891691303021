@value tablet from '~modules/common/styles/screen-sizes.module.css';

.container {
  display: grid;
  grid: '. link1 userSettings'
        '. link2 .'
        '. link3 .' / 1fr 1fr 1fr;
  justify-items: center;
  align-content: center ;
  gap: .5rem;
  border-top: 1px darkgray solid;
  border-bottom: 1px darkgray solid;

  font-size: clamp(1rem, 1.25rem, 2rem);
  font-weight: 500;
  color: var(--placeholder-color);
}

.userGroupTerm {
  grid-area: userGroup;
  display: none;
}


.navLink {
  composes: remove-link-style  from '~modules/common/styles/sharedStyles.module.css';
  color: var(--placeholder-color);
  display: flex;
  align-items: center;
}

.navLink:nth-child(1){ grid-area: link1 }
.navLink:nth-child(2){ grid-area: link2 }
.navLink:nth-child(3){ grid-area: link3 }

.container :global(.UserMenu--userSettings) {
  grid-area: userSettings;
  position: relative;
  justify-self: flex-end;
  font-size: 1.15rem;
}

.userSettings a {
  width: 100%;
}

.activeLink {
  position: relative;
  color: var(--secondary-color);
  font-weight: 900;
}

.userMenu :global(.editButton) {
  color: var(--secondary-color);
  font-size: 1.25rem;
}

.userMenu :global(.popup-menu) {
  top: 0;
}

.userGroupLink [data-icon] {
  color: orange;
}

.orgName {
  font-size: .9rem;
  font-style: italic;
}



@media tablet {
  .container {
    display: grid;
    grid: 'userGroup link1 link2 link3 userSettings' 1fr / 1fr 1.5fr 1.5fr 1.5fr 1fr;
  }
}