@value breakpoints: '~modules/common/styles/screen-sizes.module.css';
@value mobile, tablet, desktop from breakpoints;

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
}

.page :global(.NavBar--container) {
  text-align: center;
}

.header {
  composes: h2 from '~modules/common/styles/headers.module.css';
  margin-bottom: 1rem;
}


.account,
.info,
.memberships {
  position: relative;
  margin: 1rem;
  width: 95%;
  border-top: black 1px solid;
  padding: 1.5rem 2rem;
}

  .action {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }




@media tablet {
  .account,
  .info,
  .memberships {
    max-width: 1200px;
  }
}
