@value placeholderColor from '~modules/common/styles/variables.module.css';
@value primaryColor from '~modules/common/styles/variables.module.css';
@value borderRadius: 8px;

.label {
  font-weight: 700;
}
.caretRight {
  margin: 0 .5rem;
  color: var(--primary-color)
}

.inputContainer {
  margin-bottom: 1.5rem;
}


.input,
.dropDown  { 
  background: none;
  border: none;
  outline: none;
  font-size: 1rem;
  padding: 0rem .75rem;
  width: 100%;
  background: transparent;
}

.inputWrapper {
  border: 1px solid lightgray;
  border-radius: borderRadius;
  background-color: white;
  display: flex;
  margin: .25rem 0;
  height: 2rem;
  padding: 0;
}

.inputWrapper:focus-within {
  border-radius: borderRadius;
  box-shadow: 0px 0px 2px 1px rebeccapurple;
}


.inputWrapper button {
  width: 3rem;
  height: 100%;
  border:none;
  border-left: 1px solid lightgray; 
  background: none;
  border-radius: 0;
}

.helpText {
  margin: 0;
  padding: 0;
  color: darkgray;
  font-size: .7rem;
  font-weight: 400;
  font-style: italic;
}