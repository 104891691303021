.memberRow,
.addAction {
  margin: 0;
  padding: .75rem 0;
  border-top: solid 1px var(--secondary-color);
}

.memberRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.addAction {
  display: flex;
  flex-direction: row-reverse;
}

.addButton {
  background: none;
  border: none;
  width: 3rem;
}


.name,
.email {
  margin: 0;
  padding: 0;
}

.email {
  font-size: .8rem;
  font-style: italic;
}

.addMemberForm {
  position: relative;
  width: 100%;
}

.addMemberForm :global(form) {
  margin: 0;
  padding-bottom: .5rem;
  border-bottom: solid 1px var(--secondary-color);
}

.addIcon,
.plusIcon {
  color: var(--secondary-color)
}

.onCloseButton {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background:none;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: 900;
}