@value buttonWidth: 8.2rem;

.popupMenu {
  composes: swing-in-right-bck from "~modules/common/styles/animations.module.css";
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  border: solid 1px var(--gray-color);
  border-radius: 5px;
  min-width: fit-content;
  max-width: buttonWidth;
  white-space: nowrap;
  z-index: 9;

  li {
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    :hover {
      background: rgb(243, 243, 243);
    }
  }

  li:nth-of-type(1) {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  [data-disabled="true"] {
    pointer-events: none; //This makes it not clickable
    opacity: 0.8; //This grays it out to look disabled
    color: var(--placeholder-color);
  }

  [data-type="title"] {
    font-size: 0.9rem;
    padding: 0.25rem 0.25rem;
    min-width: 9rem;
    margin: 0;
    background-color: var(--secondary-color);
    color: white;
    pointer-events: none; //This makes it not clickable
  }

  [data-icon] {
    margin-right: 0.75rem;
    width: 1.5rem;
  }

  [data-icon="circle-plus"],
  [data-icon="file-excel"] {
    color: darkgreen;
  }
  [data-icon="pencil"],
  [data-icon="retweet"],
  [data-icon="filter"] {
    color: orange;
  }

  [data-icon="bars"] {
    color: var(--secondary-color);
  }

  [data-icon="cog"],
  [data-icon="landmark"],
  [data-icon="user"] {
    color: var(--primary-color);
  }
  [data-icon="trash-can"],
  [data-icon="right-from-bracket"],
  [data-icon="user-slash"] {
    color: darkred;
  }
}

@keyframes menu-appear {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 25;
  }
  50% {
    opacity: 50;
  }
  75% {
    opacity: 75;
  }
  100% {
    opacity: 100;
  }
}

.buttonIcon {
  background: none;
  border: none;
  color: var(--background-color);
}

.buttonIcon:focus {
  outline: none;
  border: 0;
}

.button {
  background: none;
  color: var(--background-color);
  border: 1px solid var(--background-color);
  padding: 0.25rem 1rem;
  border-radius: 10px;
  width: buttonWidth;

  [data-icon] {
    padding-right: 0.5rem;
  }
}
