@value variables: '~modules/common/styles/variables.module.css';
@value primaryColor, headerFont from variables;
@value tablet from '~modules/common/styles/screen-sizes.module.css';

.link {
  composes: remove-link-style from '~modules/common/styles/sharedStyles.module.css';
}

.container {
  text-align: center;
}

.service,
.hours,
.online {
  font-size: 2rem;
  font-weight: 900;
}

.service {
  color: var(--background-color);
}

.hours {
  color: var(--secondary-color);
}

.online {
  color: var(--primary-color);
}


@media desktop {
  .service,
  .hours,
  .online {
    font-size: 2.5rem;
  }
}