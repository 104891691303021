.container {
  position: relative;
  background-color: white;
  padding: 1rem;;
  border: darkgray 1px solid;
  border-radius: 10px;
}

.filterMenu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  column-gap: 1.75rem;
}

.filterTitle {
  font-size: 1.15rem;
  color: var(--secondary-color)
}

.selectButton {
  display: flex;
  column-gap: .5rem;
  background: none;
  border: none;
}

.closeButton {
  position: absolute;
  top: .5rem;
  right: 0.7rem;
  min-width: 2rem;
  font-size: 1.5rem;
  font-weight: 900;
  padding: 0;
  margin: 0;
  border: 0;
  padding: 0;
  background: transparent;
  position: absolute;
  color: darkgrey;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.applyButton {
  composes: success  from '~modules/common/components/Forms/styles/buttons.module.css';
  width: 15rem;
}