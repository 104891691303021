@value breakpoints: '~modules/common/styles/screen-sizes.module.css';
@value mobile, tablet, desktop, desktopMedium from breakpoints;

.actions {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}

.submitButton {
  composes: success from '~modules/common/components/Forms/styles/buttons.module.css';
  padding: .5rem 1.2rem
}

.disabledForm {
  input {
    pointer-events: none;
    background-color: lightgray;
  }

  :global(.dropDown) {
    background-color: lightgray;
  }
}

.performedByMessage {
  margin-top: -1.2rem;
  margin-left: .5rem;
  color: white;
  font-size: .8rem;

  a {
    color: white;
  }
}