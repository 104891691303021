@value breakpoints: '~modules/common/styles/screen-sizes.module.css';
@value mobile, tablet, desktop from breakpoints;

.invalidRequest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formContainer :global(.label) {
  color: white;
}

.message {
  font-size: 1.5rem;
  color: var(--secondary-color)
}

.intro {
  font-size: 1.25rem;
  margin-bottom: 3rem;
  text-align: center;
  color: var(--primary-color);
  font-weight: 900;
}

.container {
  padding: 1rem 2rem;
}

.actions {
  display: flex;
  justify-content: center;
}

.buttonSubmit {
  composes: success from '~modules/common/components/Forms/styles/buttons.module.css';
  margin: .5rem 0;
  width: 10rem;
  font-size: 1.5rem;
}

.rejectMessage {
  margin-top: 4rem;
  text-align: center;
  font-style: italic;
  color: var(--placeholder-color);
}

.decline {
  cursor: pointer;
  color: var(--secondary-color);
}

@media desktop {
  .container {
    padding: 3rem 8rem;
  }
}