.page :global(.PopupMenu--container) { 
  grid-area: "nav";
  justify-self: center;
}
.main { grid-area: "main";  }

.page {
  height: 100%;
  display: grid;
  grid: "nav" min-content
        "main" max-content /
         minmax(min-content, 35rem);
  justify-content: center; 
  align-content: center;
  row-gap: 1rem;
}

.main {
  margin: 2rem;
  padding: 3rem;
  min-width: 35%;
  box-shadow: 0px 0px 10px 4px var(--placeholder-color);
  border-radius: 8px;
}
