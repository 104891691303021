@value breakpoints: '~modules/common/styles/screen-sizes.module.css';
@value mobile, tablet, desktop, desktopMedium from breakpoints;

.container {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 2.5rem;
  left: 0;
  z-index: 9999;
}

@media desktop {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #625d5dd6;
  }
}

.content {
  position: absolute;
  padding: 1rem;
  width: calc(100% - 2rem); /*minus total padding*/
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media desktop {
  .content {
    margin-top: -2.5rem; /*off set for the top starting at 2.5 for nav*/
    width: 40%;
    height: auto;
    border-radius: 10px;
  }
}

.generalMessage {
  font-size: 1.5rem;
  font-weight: 900;
}

.cancelButton {
  composes: warning from '~modules/common/components/Forms/styles/buttons.module.css';
}

.confirmationButton {
  composes: error from '~modules/common/components/Forms/styles/buttons.module.css';
}

.cancelButton,
.confirmationButton {
  margin: 0 2rem;
  font-size: 1.5rem;
  font-weight: 900;
  padding: .5rem 1rem;
  width: 7rem;
}

.warningIcon {
  font-size: 7rem;
  color: orange;
}

.container p {
  margin: 0 0 1.5rem 0;
  font-weight: 800;
}

.youSure {
  font-size: 2.5rem;
}

.noFix {
  color: darkgray;
}
