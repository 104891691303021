@value variables: '~modules/common/styles/variables.module.css';
@value viewHeight from variables;
@value breakpoints: '~modules/common/styles/screen-sizes.module.css';
@value mobile, tablet, desktop, desktopMedium from breakpoints;


.container {
  font-size: .8rem;
  background: var(--background-color);
}

.sponsor {
  composes: slow-in-out from '~modules/common/styles/animations.module.css';
  margin: auto;
  cursor: pointer;
  text-align: center;
  width: 300px;
  color: white;
}


.sponsorLink {
  composes: remove-link-style from '~modules/common/styles/sharedStyles.module.css';
}

.header {
  color: var(--primary-color);
  font-size: .9rem;
  text-align: center;
  font-weight: 900;
  width: 100%;
}

.sponsor p {
  margin: .25rem 0;
}

.sponsor :nth-child(1) {
  font-family: cursive;
}
