@value primaryColor, headerFont from '~modules/common/styles/variables.module.css';
@value viewHeight from '~modules/common/styles/variables.module.css';

.container {
  font-family: cursive;
  font-size: clamp(.8rem, 7vw, 1.5rem);
  color: var(--primary-color);
  animation: pulsing 2s ease-out infinite;
  display: flex;
  align-items: center;
}

.container p {
  margin: auto;
  padding: 0;
  display: inline-block;
}

.ellipsis {
  font-size: 1.5;
  font-weight: 900;
}

@keyframes pulsing {
  0% { opacity: 0; }
  33% { opacity: 50%; }
  66% { opacity: 100%; }
  100% { opacity: 50%; }
}