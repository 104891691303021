.button {
  min-width: 4rem;
  font-size: 1rem;
  padding: .25rem;
  border-radius: 5px;
  background: none;
  border: solid 2px;
}

.button:hover {
  filter: brightness(50%);
  cursor: pointer;
}

.button:focus {
  outline-color: transparent;
}

.button:disabled {
  cursor: not-allowed;
  filter: opacity(50%);
}

.error {
  composes: button;
  border-color: red;
  color: red;
}

.warning {
  composes: button;
  border-color: var(--secondary-color);
  color: var(--secondary-color);;
}

.info {
  composes: button;
  border-color: blue;
  color: blue;
}

.success {
  composes: button;
  background: var(--secondary-color);
  border-color: var(--secondary-color);
  color: white;
}

.spinner {
  border: none;
  color: white;
  padding: 0 .5rem;
  margin: 0;
  size: .7rem;
}