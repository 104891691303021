.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.userGroup {
  margin-bottom: 2rem;
  min-width: 20rem
}

.header h3 {
  color: var(--background-color);
  margin: 0;
}
.header p {
  margin: .5rem 0;
  padding: 0;
  color: var(--placeholder-color);
  font-style: italic;
}

