.passwordDisplay,
.emailDisplay {
  display: flex;
  align-items: center;
}

.emailDisplay {
  margin-bottom: 1.5rem;
}

.label {
  flex-basis: 20%;
  font-weight: 700;
}

.value {
  flex-basis: 60%;
}

.buttonText {
  flex-basis: 20%;
  text-align: right;
  color: blue;
  cursor: pointer;
}
