.form {
  display: flex;
  flex-direction: column;

  :global(.Checkbox--buttonToggle) {
    background-color: whitesmoke;
    padding: .5rem;
    border-radius: 10px;
  }
}

.saveButton {
  composes: success from '~modules/common/components/Forms/styles/buttons.module.css';
  width: 50%;
  align-self: center;
  margin-top: 1rem;
}