@value tablet from '~modules/common/styles/screen-sizes.module.css';

.container {
  display: grid;
  grid: 'link1' 1fr
        'link2' 1fr
        'link3' 1fr 
        'link4' 1fr 
        'link5' 1fr / 1fr;
  justify-items: center;
  align-content: center ;
  gap: .5rem;
  border-top: 1px darkgray solid;
  border-bottom: 1px darkgray solid;

  font-size: clamp(1rem, 1.25rem, 2rem);
  font-weight: 500;
  color: var(--placeholder-color);
  padding: 1rem;
}

.container :global(.UserMenu--userSettings) {
  grid-area: userSettings;
  grid-column-start: 1;
  grid-row-start: 1;
  position: relative;
  justify-self: flex-end;
  font-size: 1.15rem;
}

.navLink {
  composes: remove-link-style  from '~modules/common/styles/sharedStyles.module.css';
  color: var(--placeholder-color);
  align-items: center;
}

.activeLink {
  position: relative;
  color: var(--secondary-color);
  font-weight: 900;
}

.navLink:nth-child(1){ grid-area: link1 }
.navLink:nth-child(2){ grid-area: link2 }
.navLink:nth-child(3){ grid-area: link3 }
.navLink:nth-child(4){ grid-area: link4 }
.navLink:nth-child(5){ grid-area: link5 }


@media tablet {
  .container {
    display: grid;
    grid: ' link1 link2 link3 link4 link5 userSettings' 1fr / 1fr 1fr 1fr 1fr 1fr 1fr ;

  }

  .container :global(.UserMenu--userSettings) {
    grid-area: userSettings;
  }
}