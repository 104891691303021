/* ----------------------------------------------
 * Generated by Animista on 2021-3-12 16:12:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

.scale-out-center {
  animation: scale-out-center 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes scale-out-center {
 0% {
   transform: scale(1);
   opacity: 1;
 }
 100% {
   transform: scale(0);
   opacity: 1;
 }
}



.swing-in-right-bck {
  animation: swing-in-right-bck .6s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@keyframes swing-in-right-bck {
 0% {
   transform: rotateY(70deg);
   transform-origin: right;
   opacity: 0;
 }
 100% {
   transform: rotateY(0);
   transform-origin: right;
   opacity: 1;
 }
}


.swing-in-top-fwd {
    animation: swing-in-top-fwd .6s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
} 
@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}

.slow-in-out {
  animation: fade-in 4s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.slide-in-elliptic-right-bck {
	animation: slide-in-elliptic-right-bck 6s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s infinite reverse both;
}

@keyframes slide-in-elliptic-right-bck {
  0% {
    transform: translateX(800px) rotateY(30deg) scale(6.5);
    transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    transform: translateX(0) rotateY(0) scale(1);
    transform-origin: 600px 50%;
    opacity: 1;
  }
}