@value desktop from from '~modules/common/styles/screen-sizes.module.css';

.container {
  position: relative;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: min-content min-content min-content max-content;
  row-gap: 0.5rem;
  padding: .75rem;

  > :global(.OrgDetailPageWrapper--header) {
    grid-row: 3/3;
    grid-column: 1/-1;
  }

  > :global(.ServiceTotals--container) {
    grid-row: 2/2;
    grid-column: 1/-1;
  }

  > :global(.TimeFrameFilters--filters) {
    grid-row: 1/1;
    grid-column: 1/-1;
    padding: 0.5rem 0;
    justify-self: center;
  }

  > :global(.UserGroupsServices--tools) {
    grid-row: 3/3;
    grid-column: 1/-1;
    align-self: end;
    justify-self: end
  }


  > :global(.UserGroupsServices--tooManyMessage ) {
    position: absolute;
    top: .5rem;
    color: red;
    font-style: italic;
  } 

  
  > :global(.ServiceTable--notFound),
  > :global(.ServiceTable--table),
  > :global(.InLineForm--container),
  > :global(.ServiceFilters--container) {
    grid-column: 1 / -1;
  }
}

@media desktop {
  .container {
    > :global(.TimeFrameFilters--filters) {
      grid-column: 2/ 2;
      justify-self: start;
    }

    > :global(.UserGroupsServices--tools) {
      grid-row: 1/1;
      grid-column: 3/3;
      align-self: center;
    }

    :global(.ServiceForm--formGroup) {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      margin: 0;
    }

    :global(.input-container) {
      width: 100%;
      margin: 0.5rem 0;
    }
  }
}
