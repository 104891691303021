@value desktop from from '~modules/common/styles/screen-sizes.module.css';

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form {
  > :global(.input-container) {
    margin: .5rem;
  }
}

.org {
  flex-basis: 47%;
}

.defaultPassword {
  flex-basis: 26%;
}

.zip {
  flex-basis: 17%;
}

.email,
.number {
  flex-basis: 47%;
}

.groupType,
.singular,
.plural {
  flex-basis: 30%;
}

.submitButton {
  composes: success from "~modules/common/components/Forms/styles/buttons.module.css";
  margin: .5rem;
}

@media desktop {
  .form {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
