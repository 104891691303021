.addMemberForm {
  :global(.Checkbox--buttonToggle) {
    background-color: whitesmoke;
    padding: .5rem;
    border-radius: 10px;
  }
}

.errorText {
  color: red;
  size: 1.5rem;
  padding: .5rem;
  text-align: center;
}

.actions {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}

.submitButton {
  composes: success from '~modules/common/components/Forms/styles/buttons.module.css';
  padding: .5rem 1.2rem
}