@value tablet-break, desktop-break, desktop-medium-break, desktop from '~modules/common/styles/screen-sizes.module.css';

.notFound {
  font-size: 1.5rem;
  text-align: center;
  margin: 3rem;
  color: var(--secondary-color);
  font-style: italic;
  font-weight: 800;
}

.table td>label {
  display: none;
}

.table {
  width: 100%;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 0.75rem 0.5rem;
  text-align: left;
}

.table thead {
  color: white;
  background: var(--secondary-color);
}

.table thead th {
  padding: 0.5rem;
}

.table tbody {
  font-size: .85rem;
}

.table tr:nth-child(odd):not(.editForm) {
  background: var(--table-row-color);
}

.table td {
  border-top: solid 2px var(--secondary-color);
  border-bottom: var(--secondary-color);
  text-overflow: ellipsis;
}

.editIcon {
  position: relative;
}

.selector {
  align-self: flex-end;
}



@media  (max-width: tablet-break),
        (min-width: desktop-break) and (max-width: desktop-medium-break)  {

  .table thead {
    display: none;
  }

  .table tr {
    display: flex;
    flex-direction: column;
  }

  .table td {
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: right;
    padding: .25rem .75rem;
  }

  .table td > label {
    display: inline;
    font-weight: 900;
  }

  .table tr {
    padding: 2rem .25rem;
    border-radius: 10px;
  }

}


@media desktop {
  .editForm {
    :global(.ServiceForm--formGroup) {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      margin: 0;
    }

    :global(.input-container) {
      width: 100%;
      margin: .5rem 0;
    }

  }
}