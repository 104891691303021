@value desktop from '~modules/common/styles/screen-sizes.module.css';

.serviceRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1rem 0;
  border-top: solid 1px var(--primary-color) ;
}

.hours {
  flex-basis: 5rem;
  text-align: center;
  background-color: var(--secondary-color);
  padding: .25rem;
  border-radius: 5px;
  font-weight: 900;
  color: white;
}

.date {
  flex-basis: 3rem;
  padding: .15rem 1rem;
  font-size: 1.15rem;
  font-weight: 700;
  text-align: center;
  color: var(--background-color);
}

.content {
  flex-basis: 90%;
}

.activity {
  font-size: 1.25rem;
}
.performedByUser{
  font-style: italic;
}

.action {
  position: relative;
  align-self: center;
}

.action :global(.popup-menu) {
  top: -1.5rem;
}


@media desktop {
  .editForm {
    :global(.ServiceForm--formGroup) {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      margin: 0;
    }

    :global(.input-container) {
      width: 100%;
      margin: .5rem 0;
    }

  }
}