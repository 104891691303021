@value desktop from '~modules/common/styles/screen-sizes.module.css';

.actionButtons {
  position: relative;

  :global(.PopupMenu--popupMenu) {
    min-width: 10rem;
  }
}

.overwritten {
  color: orange;
  text-align: center;
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 1rem;
}

.saveButton {
  composes: success from "modules/common/components/Forms/styles/buttons.module.css";
  max-width: 20rem;
  align-self: center;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
}

.form {
  display: flex;
  flex-direction: column;

  :global(.form-group) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    :global(.input-container) {
      margin: 0.5rem 0.5rem;
    }

    :global(.input-container:nth-of-type(1)),
    :global(.input-container:nth-of-type(2)) {
      flex-basis: 100%;
      flex-grow: 1.2;
    }
    :global(.input-container:nth-of-type(3)) {
      flex-basis: 9rem;
      flex-grow: 1.5;
    }
    :global(.input-container:nth-of-type(4)) {
      flex-basis: 6rem;
      flex-grow: 0;
    }
    :global(.input-container:nth-of-type(5)) {
      flex-basis: 100%;
    }
  }
}

@media desktop {
  .form {
    :global(.form-group) {
      :global(.input-container:nth-of-type(1)),
      :global(.input-container:nth-of-type(2)) {
        flex-basis: 7rem;
      }
    }
  }
}
