
.buttonToggle {
  display: flex;
  align-items: center;

  background: none;
  border: none;
  
  [data-icon] {
    font-size: 1.25rem;
  }
  
}

.toggleOn {
  color: green
}

.toggleOff {
  color: var(--background-color);
}

.label { margin-left: .5rem;}
.labelBefore { margin-right: .5rem;}

