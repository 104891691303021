@value  desktop from '~modules/common/styles/screen-sizes.module.css';

.page {
  min-height: 100vh;
  display: grid;
  grid: "nav" max-content
        "orgName" max-content
        "userGroups" max-content
        "nested" 1fr / 1fr;
  > * {
    padding: 1rem;
  }

  > :global(.NavBar--container) {
    grid-area: nav;
    justify-self: center;
  }

  > .orgHeader {
    grid-area: orgName;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background-color);
  }

  > :global(.OrgUserGroupsNavigation--container) {
    grid-area: userGroups;
    padding-bottom: 0;
  }

  .nested {
    grid-area: nested;
    overflow: scroll;
  }

}

.orgHeader [data-icon] {
  font-size: 1.25rem;
  color: white;
}

.orgTitle {
  composes: h2 from '~modules/common/styles/headers.module.css';
  font-size: 1.25rem;
  color: white;
}

@media desktop {
  .page {
    max-height: 100vh;
    grid: "orgName nav" 8vh
          "userGroups nested" 92vh /
          minmax(min-content, 25rem) 1fr;
    column-gap: 3rem;

    > :global(.OrgUserGroupsNavigation--container) {
      padding-right: 0;
    }

  }
}