.container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
}


.userGroupList {
  flex-basis: 95%;
  overflow: scroll;
}

.buttonContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: .1rem 0 1rem 0;
}

.selectAllButton {
  font-size: 1.25rem;
  padding: 0 .25rem;
}

.showList,
.inactiveToggle,
.addUserGroup {
  composes: button-icon from '~modules/common/styles/sharedStyles.module.css';
}


.inactiveToggle,
.selectAllButton {
  color: var(--background-color); 
}

.selectedCount {
  composes: selected-count from '~modules/common/styles/sharedStyles.module.css';
  text-align: right;
}

.inactiveToggle {
  width: max-content;
  text-align: left;
  align-self: flex-end;
  font-size: 1rem;
  display: flex;
  align-items: center;

  [data-icon] {
    font-size: 1.25rem;
    margin-left: .5rem;

  }

  [data-icon="toggle-on"] {
    color: green;
  }

}

.header {
  composes: h2 from '~modules/common/styles/headers.module.css';
}


.input {
  margin: 0;
}

.viewSelected {
  composes: success from '~modules/common/components/Forms/styles/buttons.module.css';
}

.userGroupTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: .75rem;
}

.userGroupTitle {
  position: relative;

  :global(.PopupMenu--popupMenu) {
    min-width: 11rem;
  }
}

.sortContainer {
  position: relative;

  :global(.PopupMenu--button) {
    width: 5.5rem;
  }
}

.sortRow {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  [data-icon="check"] {
    color: green;
  }

}

