.remove-link-style {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.button-icon {
  border: none;
  background: none;
  font-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.selected-count {
  color: var(--placeholder-color);
  font-size: .85rem;
}

.resetList {
  margin:0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}