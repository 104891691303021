@value desktop from '~modules/common/styles/screen-sizes.module.css';

.container {
  margin: auto;
  max-width: 1000px;
}
.header {
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 1000px;
  background: white;
  z-index: 99;
}

.content {
  padding: .5rem;
}

.returnContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -3rem;
}
.returnButton {
  margin-top: 1rem;
  margin-right: 1rem;
  [data-icon] {
    padding-right: .5rem;
  }
}

.titles {
  composes: h2 from "~modules/common/styles/headers.module.css";
  margin-bottom: 1rem;
}

.headerList {
  composes: resetList from "~modules/common/styles/sharedStyles.module.css";
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1rem;
  padding: 1rem .5rem;
  font-size: 1.1rem;
  color: var(--gray-color);
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}

.orgInfo,
.activities,
.targets,
.targetTypes,
.sponsors,
.targetRules {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.orgInfo {
  :global(.OrgInfoForm--form) {
    margin-top: 1rem;
  }
}



