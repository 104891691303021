@value desktop from from '~modules/common/styles/screen-sizes.module.css';
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  composes: h2 from '~modules/common/styles/headers.module.css';
}

.serviceTotal {
  composes: h3 from '~modules/common/styles/headers.module.css';
  width: 100%;
}
.date {
  font-size: .85rem;
  font-style: italic;
}

.totals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 2rem;

  @media desktop {
    flex-direction: row-reverse;
    flex-wrap: nowrap;
  }
}

.memberTotals,
.activityTotals,
.allTotal {
  width: 100%;

  @media desktop {
    max-width: 25rem;
    min-width: 10rem;
  }
}

.allTotal {
  width: 8rem;
  text-align: right;
}

.record {
  margin-bottom: .5rem;
}
.recordData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.percentIndicator {
  width: 100%;
  border: 2px solid var(--primary-color);
}

.showMoreButton {
  background: none;
  border: none;
  color: darkblue;
  width: 100%;
}

.showMoreButton:hover {
  font-weight: 700;
}
