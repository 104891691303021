@value tablet-break, desktop-break, desktop-medium-break, desktop from '~modules/common/styles/screen-sizes.module.css';

.hours,
.edit {
  text-align: right;
}
.description {
  max-width: 20rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (max-width: tablet-break), (min-width: desktop-break) and (max-width: desktop-medium-break) {
  .description {
    max-width: none;
  }
  .editForm {
    td {
      display: initial;
      text-align: left;
    }
  }
}

@media desktop {
  .editForm {
    :global(.InLineForm--container) {
      display: flex;
      justify-content: center;
    }
    :global(.ServiceForm--form) {
      max-width: 65rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0;
      gap: 1rem;

      > :global(.input-container) {
        flex-basis: 20rem;
        margin: 0;
      }
      > :global(.ServiceForm--serviceDate) {
        flex-basis: 10rem;
      }
      > :global(.ServiceForm--hours) {
        flex-basis: 8rem;
      }
      > :global(.ServiceForm--description) {
        flex-basis: 100%;
      }
    }

    :global(.ServiceForm--performedByHidden) {
      max-width: 42rem;
    }

  }
}
