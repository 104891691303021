@value desktop from '~modules/common/styles/screen-sizes.module.css';


.form,
.createForm {
  display: flex;
  flex-direction: column;

  > :global(.input-container) {
    margin: 0;
  }
}

.createForm {
  :global(.input::placeholder) {
    color: transparent
  }
}
.form {
  :global(.label-text) {
    display: none;
  }

}

.form {
  :global(.inputWrapper) {
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
  }
  :global(.inputWrapper):focus-within {
    box-shadow: 0px 2px 0px 0px rebeccapurple;
  }
}

.updateError {
  color: red;
  font-weight: 600;
  margin-top: 1rem;
}

.businessName {
  flex-basis: 30%;
}

.phone {
  flex-basis: 20%;
}

.url {
  flex-basis: 40%;
}
.buttons {
  flex-basis: 10%;
}

.addButton {
  composes: success from '~modules/common/components/Forms/styles/buttons.module.css';
}

.buttons {
  align-self: flex-end;
  margin-top: 0.5rem;


  button {
    margin-left: 0.5rem;
  }
}

.deleteButton {
  color: darkred;
}

.submitButton {
  color: var(--secondary-color);
}

.addButton {
  margin-top: 1.4rem;
  height: 2.2rem;
}

.showCreate {
  color: green;
  font-size: 1.5rem;
  min-width: 2rem;
  border: none;
  padding: 0.5rem;
  margin: 0;
}

@media desktop {
  .form,
  .createForm {
    flex-direction: row;
    align-content: center;

    :global(.inputWrapper) {
      margin-right: 1rem;
    }
  }


  .buttons {
    display: flex;
    justify-content: center;
    align-self: center;

    button {
      min-width: 2rem;
      border: none;
      padding: 0.5rem;
      margin: 0;
    }

  }

}
