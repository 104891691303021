@value desktop from from '~modules/common/styles/screen-sizes.module.css';
.header {
  composes: h2 from '~modules/common/styles/headers.module.css';
  margin-top: 2rem;
}

.container {
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  grid-template-rows: min-content min-content max-content;
  align-items: end;
  row-gap: 0.25rem;
  padding: .75rem;

  > :global(.UserGroupsTargets--countDownload) {
    grid-column: 2/2;
    grid-row: 1/1;
    justify-self: end;
  }

  > :global(.TimeFrameFilters--filters) {
    grid-column: 1/-1;
    grid-row: 2/2;
    justify-self: center;
    padding: 0.5rem 0;
    
  }

  > :global(.UserGroupsTargetManagement--actionButtons) {
    grid-column: 3/3;
    grid-row: 1/1;
    align-self: end;
    justify-self: end;
  }

  > :global(.TargetFilters--container) {
    grid-row: 3/3;
    grid-column: 1/-1;
  }
 

  > :global(.TargetList--table),
  > :global(.TargetList--notFound),
  > :global(.InLineForm--container),
  > .tooMany {
    grid-column: 1/-1;
  }
}

.selectUserGroup {
  grid-column: 1/1;

  p {
    font-style: italic;
    color: var(--background-color);
    font-size: 1.5rem;
    display: inline;
    margin: 1rem;
  }
}


@media desktop {
  .container {
    > :global(.TimeFrameFilters--filters) {
      justify-self: start;
    }

    > :global(.UserGroupsTargetManagement--actionButtons) {
      grid-column: 3/3;
      grid-row: 2/2;
      align-self: center;
    }
  }

}
