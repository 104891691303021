@value desktop from '~modules/common/styles/screen-sizes.module.css';
.createForm,
.editForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 1rem;
}
.name,
.description {
  flex-basis: 100%;
}
.maxHours,
.activitiesClusivity {
  flex-basis: 48%;
}

.activityList {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 0.5rem;
  margin-bottom: 1rem;
  padding: 0;
}

.activity,
.selectedActivity {
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  background-color: white;
  cursor: pointer;
  font-size: 0.9rem;
}
.selectedActivity {
  background-color: var(--primary-color);
  color: black;
  font-weight: 600;
}

.addButton {
  composes: success from "~modules/common/components/Forms/styles/buttons.module.css";
  align-self: center;
  flex-basis: 100%;
}

.showCreate {
  color: green;
  font-size: 1.5rem;
  min-width: 2rem;
  border: none;
  padding: 0.5rem;
  margin: 0;
}

.rule {
  position: relative;
  width: 100%;
  border-bottom: solid 1px var(--gray-color);
  padding-bottom: 1rem;

  :global(.TargetRule--header) {
    max-width: 80%;
  }
}

.editButton {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--secondary-color);
}

.saveButton,
.removeButton {
  width: 7rem;
}
.saveButton { composes: success from '~modules/common/components/Forms/styles/buttons.module.css';}
.removeButton {
  color: white;
  background: darkred;
  border-color: darkred;
  }

@media desktop {
  .createForm,
  .editForm {
    > :global(.input-container) {
      margin-bottom: 0;
    }
  }

  .name {
    flex-basis: 40%;
    flex-grow: 1.5;
  }
  .maxHours {
    flex-basis: 8rem;
  }
  .activitiesClusivity {
    flex-basis: 13rem;
  }

  .editButton {
    right: 2rem;
    min-width: 2rem;
    border: none;
    padding: 0.5rem;
    margin: 0;
  }
}
