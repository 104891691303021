@value desktop from '~modules/common/styles/screen-sizes.module.css';

.header {
  composes: h1 from '~modules/common/styles/headers.module.css';
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.exportButton {
  composes: button from '~modules/common/components/Forms/styles/buttons.module.css';
  color: green;
  width: 5.5rem;
}