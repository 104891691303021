@value desktop from '~modules/common/styles/screen-sizes.module.css';
.filters {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 999;
}

.button {
  min-width: 4rem;
  max-width: 6rem;
  color: white;
  border-radius: 0;
  background: var(--background-color);
  border: 1px solid var(--background-color);
  border-right: 1px solid var(--gray-color);
  border-left: 1px solid var(--gray-color);
}

.button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: none;
}
.button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-right: none;
}

.activeButton {
  background: var(--primary-color);
  color: black;
  border-bottom: none;
  border-top: none;
}

@media desktop {
  .button {
    min-width: 6rem;
    max-width: 8rem;
  }
}

.customDateForm {
  position:absolute;
  top: 50%;
  border: 1px solid darkgray;
  border-radius: 10px;
  padding: .5rem;
  background: white;
}


.applyButton {
  width: 100%;
  composes: success  from '~modules/common/components/Forms/styles/buttons.module.css';
}