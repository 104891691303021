@value desktopMedium from '~modules/common/styles/screen-sizes.module.css';
@value variables: '~modules/common/styles/variables.module.css';

.container {
  background: var(--background-color);
}

.orgName {
  composes: h2 from '~modules/common/styles/headers.module.css';
  color: white;
}

.userGroupName {
  font-size: 1rem;
  font-style: italic;
  margin: .5rem 0 0 0;
  color: var(--gray-color);
}

.userGroupName label {
  font-style: normal;
  font-weight: 600;
}