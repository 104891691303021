@value desktop from '~modules/common/styles/screen-sizes.module.css';

.tools {
  position: relative;
}
.actions {
  margin-bottom: 1rem;
}


.title {
  composes: h2 from '~modules/common/styles/headers.module.css';
}


.plusIcon {
  color: var(--secondary-color);
}



