.container {
  margin-top: .75rem;
}

.name {
  font-weight: 900;
}

.header,
.maxHours {
  margin-bottom: .25rem;
}


.maxHours,
.activities {
  label {
    font-size: .95rem;
    font-style: italic;
  }
}


.activity,
.description {
  font-size: .85rem;
}