@value button-margin: .5rem;

.userGroup {
  display: flex;
  align-items: center;
  padding: .25rem 0;
}

.userGroup:nth-of-type(1) {
  border-top: solid 1px var(--gray-color);
  padding-top: 1rem;
}

.userGroup:hover {
  cursor: pointer;
}

.userGroupInfo {
  flex-basis: 100%;

  margin-left: button-margin;
}

.nameLine {
  display: flex;
  align-items: center;
  position: relative;
}

.userGroupName {
  flex-basis: 80%;
  flex-grow: 2;
}

.inactiveTime,
.createTime {
  font-size: .8rem;
  justify-self: flex-end;
  margin-left: .7rem;
  flex-basis: 10%;
}

.inactiveTime {
  color: red;
}
