@value desktop from '~modules/common/styles/screen-sizes.module.css';

.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem .25rem 0 .25rem;
  max-width: fit-content;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 10px;
  padding: 1rem;

  p:nth-of-type(1) {
    font-weight: 900;
    color: var(--secondary-color);
    text-align: center;
    margin-bottom: .5rem;
  }

  p:nth-of-type(2) {
    font-style: italic;
    color: red;
  }
}

.actionButtons {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}

.cancel,
.delete {
  width: 100%;
  margin-top: 1rem;
}

.cancel {
  color: var(--primary-color);
}

.delete {
  color: darkred;
}
