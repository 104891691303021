.container {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  margin: .2rem;
  padding:  .2rem .5rem;
  background: lightblue;
  border-radius: 6px;
  font-size: .7rem;
  display: flex;
  align-content: stretch;
  justify-content: space-between;
}


.removeIcon {
  padding: .1rem .25rem .1rem .5rem;
  color: white;
}
