@value primaryColor: #1B3042;
@value placeholderColor: #a9a9a9;
@value headerFont: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
@value headerHight: 2.5rem;
@value viewHeight: calc(95vh - headerHight);

:root {
  --background-color: #1a2975;
  --primary-color: #81E2E3;
  --table-row-color: #81e1e36c;
  --secondary-color: #00B7B8;
  --gray-color: #cccccc;
  --header-height: 2.5rem;
  --placeholder-color: #a9a9a9;
  --header-font: Helvetica,Neue,Arial,sans-serif;
  --user-group-page-content-left: 30%;
  --primary-header-height: 3rem;
}