@value desktop from '~modules/common/styles/screen-sizes.module.css';

.page {
  display: grid;
  grid:
    'nav'
    'userGroupHeader'
    'userNavigation'
    'entry'
    'main'
    'sponsors';

  > * {
    padding: 1rem ;
  }

  > :global(.NavBar--container) {
    grid-area: nav;
    justify-self: center;
  }
  > :global(.UserGroupHeader--container) {
    grid-area: userGroupHeader;
  }

  > :global(.CreateServiceEntry--container) {
    grid-area: entry;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
    background: var(--background-color);

    :global(.input-container) {
      margin: 1.5rem 0;
    }

    :global(.CreateServiceEntry--header) {
      font-size: 1.2rem;
      color: var(--primary-color);
    }

    :global(.label) {
      color: white;
    }
  }

  > :global(.UserNavigation--container) {
    grid-area: userNavigation;
  }

  > .nestedPages {
    grid-area: main;
  }

  > :global(.Sponsors--container) {
    grid-area: sponsors;
  }
}

@media desktop {
  .page {
    height: 100vh;
    display: grid;
    grid:
      'userGroupHeader nav' min-content
      'entry userNavigation' min-content
      'entry main' 1fr
      'sponsors main' min-content /
      minmax(min-content, 27rem) 1fr;
    column-gap: 3rem;
    // margin-right: 3rem;

    // > * {
    //   padding: 1rem 0rem;
    // }

    > :global(.CreateServiceEntry--container),
    > :global(.UserGroupHeader--container) {
      margin: 0;
      padding: 1rem 2rem;
      overflow-x: auto;
    }

    > :global(.UserNavigation--container) {
      margin: 1rem;
    }

    .nestedPages {
      overflow: scroll;
    }
  }
}
