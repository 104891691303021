.opportunitiesHeader {
  composes: h1 from '~modules/common/styles/headers.module.css';
}

.opportunities {
  font-size: 1.25rem;
}
.opportunities span {
  color: var(--placeholder-color)
}
.opportunities [data-icon="user-ninja"] {
  color: var(--primary-color);
}