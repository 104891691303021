.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  >:global(.ServiceByDateChart--container) {
    padding: 2rem 0;
    height: 20rem;
    width: 100%;
  }

  >:global(.ServiceByActivity--container){
    padding: 2rem 0;
    width: 100%;
  }

  >:global(.ServiceByUserGroups--container) {
    padding: 2rem 0;
    width: 100%;
  }

  >:global(.TimeFrameFilters--filters) {
    padding: 2rem;
  }
}

.totals {
  font-size: 1.5rem;
  text-align: center;
}

.totalHours,
.totalUserGroups {
  font-weight: 900;
  color: var(--background-color)
}