.details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.progressBar {
  align-self: flex;
  flex-basis: 85%;
  margin: .5rem 0;
}

.hours {
  font-size: .8rem;
  margin: 0
}

.total {
  border-bottom: 1px solid black;
}
.total,
.requiredHours {
  margin: 0;
  text-align: center;
}
