.h1 {
  color: var(--secondary-color);
  display: inline-block;
  margin:0;
  font-size: 2.7rem;
  font-weight: normal;
}

.h2 {
  color: var(--primary-color);
  display: inline-block;
  margin:0;
  font-size: 1.5rem;
  font-weight: 800;
}

.h3 {
  color: var(--background-color);
  display: inline-block;
  margin:0;
  font-size: 1.2rem;
  font-weight: 800;
}