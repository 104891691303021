@value variables: '~modules/common/styles/variables.module.css';
@value viewHeight from variables;
@value nested-page-break from '~modules/common/styles/screen-sizes.module.css';

.container {
  > :global(.Targets--targetHeader) {
    grid-area: tHeader;
  }
  > :global(.Targets--progress) {
    grid-area: progress;
  }
  > :global(.Targets--lifetime) {
    grid-area: lifetime;
  }
  > :global(.UserOpportunities--opportunitiesHeader) {
    grid-area: oHeader;
    border-top: solid 1px black;
    padding-top: 2rem;
  }
  > :global(.UserOpportunities--opportunities) {
    grid-area: opportunities;
    border-bottom: solid 1px black;
    padding-bottom: 2rem;
  }
  > :global(.ServiceList--activitiesHeader) {
    grid-area: aHeader;
  }
  > :global(.ServiceList--quoteContainer) {
    grid-area: quote;
  }
  > :global(.ServiceList--serviceList) {
    grid-area: serviceList;
  }
}

.container {
  display: grid;
  grid:
    'tHeader tHeader' min-content
    'lifetime progress' max-content
    'oHeader oHeader' min-content
    'opportunities opportunities' max-content
    'aHeader aHeader' min-content
    'quote serviceList' max-content /
    minmax(min-content, 20rem);
  gap: 2rem 4rem;
}

@value tablet-break, desktop-break, desktop-medium-break, nested-page-break from '~modules/common/styles/screen-sizes.module.css';
@media (max-width: tablet-break),
      (min-width: desktop-break) and (max-width: desktop-medium-break) {
  .container {
    display: grid;
    grid:
      'tHeader' min-content
      'progress' max-content
      'quote' max-content
      'aHeader' min-content
      'serviceList' max-content 
      'oHeader' min-content
      'opportunities' max-content
      'lifetime' max-content
      / 1fr;
    gap: 2rem;
  }

    > :global(.ServiceList--serviceList),
    > :global(.Targets--progress)  {
      margin-top: 0rem;
    }
}

