.container {
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--background-color);

  width: 100%;
  height: 100vh;
}

.content {
  background: white;
  border-radius: 10px;
  padding: 3rem;
  height: clamp(20rem, 50%, 75%);
  width: fit-content;
  max-width: 30rem;
  height: min-content;
}


.message,
.action,
.contact {
  word-wrap: normal;
  text-align: center;
}

.message {
  font-size: 1rem;
  font-weight: 900;
}

.action {
  padding: 2rem;
  color: var(--background-color);
  background-color: rgb(238, 237, 237);
  font-weight: 600;
  margin: 2rem -3rem;
  font-size: 1.25rem;
}

.login {
  composes: success from '~modules/common/components/Forms/styles/buttons.module.css';
  color: white;
  display: block;
  width: 7rem;
  font-size: 1.15rem;
  margin: auto;
  margin-top: 1rem;
}

.contact {
  font-style: italic;
  font-weight: 600;
  color: var(--secondary-color);
}
