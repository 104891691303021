.header {
  composes: h2 from '~modules/common/styles/headers.module.css';
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 900;
}

.button {
  composes: success from '~modules/common/components/Forms/styles/buttons.module.css';
  font-size: 1.5rem;
  margin-top: .5rem;
  float: right;
}

.formFields input { 
  font-size: 1.2rem;
}

.formFields label {
  font-size: 1rem;
}

.emailSentMessage {
  font-size: 1.5rem;
  font-weight: 900;
}

.subMessage {
  font-size: 1rem;
  font-weight: normal;
  font-style: italic;
}

.messageEmail {
  color: darkred
}