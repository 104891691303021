@value desktop from '~modules/common/styles/screen-sizes.module.css';

.title {
  composes: h1 from '~modules/common/styles/headers.module.css';
}

.header {
  composes: h3 from '~modules/common/styles/headers.module.css';
  margin-bottom: 1rem;
}

.quoteContainer {
  font-size: 2rem;
  text-align: center;
  color: var(--gray-color)
}

.author {
  font-size: .9rem;
}

.activitiesHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

}
