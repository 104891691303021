.selectButton {
  margin: 0;
  width: 1.1rem;
  height: 1.1rem;
  background: white;
  border: 2px var(--placeholder-color) solid;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: .5rem; //Safari Fix
}

.selectedButton {
  background: var(--primary-color);
  border: none;
}

.selectButton [data-icon="check"] {
  color: white;
  font-size: .75rem;
}

.selectAllContainer {
    display: flex;

    > .selectButton{
      margin-right: .5rem;
    }
}