.background, 
.foreground {
  border-radius: 10px;
  min-height: 1rem;
}

.background {
  position: relative;
  background-color: var(--gray-color);
  width: 100%;
}

.foreground {
  background-color: var(--primary-color);
  padding: .2rem 0rem .2rem .5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.label {
  position: absolute;
  padding: .2rem 0rem .2rem .5rem;
  font-size: .8rem;
  font-weight: 500;
}