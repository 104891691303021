@value desktopMedium from '~modules/common/styles/screen-sizes.module.css';

.targetHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.targetDate {
  font-size: .85rem;
  margin: .25rem 0;
  font-style: italic;
}

.title {
  composes: h1 from '~modules/common/styles/headers.module.css';
}

.header {
  composes: h3 from '~modules/common/styles/headers.module.css';
  text-transform: capitalize;
  margin-bottom: .5rem;
}

.lifetime {
  color: var(--secondary-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.circle {
  height: 8rem;
  width: 8rem;
  border-radius: 50%;
  border: solid .75rem var(--background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hours {
  font-size: clamp(1.75rem, 2rem, 2.5rem);
  font-weight: 900;
}

.lifetimeText {
  font-size: .85rem;
}

@media desktopMedium {
  .progress {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .progressGroup {
    min-width: 20rem;
    max-width: 40%;
    margin: 0rem 1rem;
  }
}