
.container {
  composes:  swing-in-top-fwd from '~modules/common/styles/animations.module.css';
  background-color: var(--background-color);
  padding: 2rem 1rem;
  border-radius: 10px;
  margin: .5rem 0;
}

.container :global(.label) {
  color: white;
}

.onCloseButton {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background:none;
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: 900;
}

.onCloseButton:active{
  outline: none;
}

.formContainer > :global(.label) {
  color: white;
}

.formContainer > h2 {
  color: var(--primary-color)
}

.formContainer > :global(.NotificationMessage--container) {
  margin: 1rem;
}