@value tablet from '~modules/common/styles/screen-sizes.module.css';

.userSettings {
  font-size: 1.15rem;
}

.userSettings a {
  width: 100%;
}

.userMenu :global(.popup-menu) {
  top: 0;
}

.userGroupLink [data-icon] {
  color: orange;
}

.orgName {
  font-size: .9rem;
  font-style: italic;
}

.navLink {
  composes: remove-link-style  from '~modules/common/styles/sharedStyles.module.css';
  color: var(--placeholder-color);
  display: flex;
  align-items: center;
}