
@supports (display: grid) {
  .ieMessage {
    display: none;
  }
}

.ieMessage {
  background: yellow;
  padding: .5rem;
  width: 100%;
  text-align: center;
  color: red;
}

/* Set the div for the route/page to 100vh*/
.container > div:nth-child(1) {
  height: 100vh;
}

.isFetching {
  position: fixed;
  top: 1rem;
  right: 1.5rem;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color);
  color: white;
}

.loadingMessage {
  font-size: 1rem !important;
  color: var(--secondary-color);
  font-style: italic;
}

.loadingIcon {
  composes: slide-in-elliptic-right-bck from '~modules/common/styles/animations.module.css';
  margin: 2rem 0 1rem 0;
  font-size: 5rem;
}