@value breakpoints: '~modules/common/styles/screen-sizes.module.css';
@value mobile, tablet, desktop from breakpoints;

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container :global(.NavBar--container) {
  font-weight: 900;
  margin-bottom: 3rem;
}

.content {
  width: 100%;
  padding: 2rem;
  box-shadow: 0px 0px 10px 4px var(--placeholder-color);
  border-radius: 8px;
  background-color: var(--background-color);
}

@media desktop {
  .content {
    width: 1024px;
  }
}