@value desktop from from '~modules/common/styles/screen-sizes.module.css';

.container {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: min-content max-content;
  row-gap: 0.5rem;
  padding: .75rem;



  > :global(.UserGroupMembers--addMemberContainer) {
    grid-row: 2/2;
    grid-column: 1/-1;
  }

  > :global(.UserGroupMembers--tools) {
    grid-row: 1/1;
    grid-column: 3/3;
    align-self: end;
    justify-self: end;
  }

  > table {
    grid-row: 3/3;
    grid-column: 1 / -1;
  }
}

@media desktop {
  .container {
    > :global(.UserGroupMembers--addMemberContainer) {
      grid-row: 2/2;
      grid-column: 1/-1;
    }
    > table {
      grid-row: 3/3;
      grid-column: 1 / -1;
    }
  }
}
