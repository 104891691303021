.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}

.bug {
  color: green;
  font-size: 10rem;
}

.page {
  margin: 0;
}

.message {
  font-weight: 900;
  font-size: 1.5rem;
  margin-bottom: 4rem;
  margin-bottom: .5rem
}

.close {
  font-style: italic;
  margin: 0;
}